.daily-quest-spot-1 {
  position: absolute;
  top: 22%;
  left: -22%;
  width: 69%;
  height: 72%;
  background: rgba(36, 0, 255, 0.2);
  filter: blur(200px);
  transform: matrix(-0.2, -0.97, 0.99, -0.17, 0, 0);
}

.daily-quest-spot-2 {
  position: absolute;
  width: 65%;
  height: 70%;
  right: -26%;
  top: -9%;
  background: rgba(250, 0, 255, 0.2);
  filter: blur(200px);
  transform: matrix(-0.2, -0.97, 0.99, -0.17, 0, 0);
}

.daily-quest-title {
  background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  filter: drop-shadow(34.2571px 31.0455px 52.4562px rgba(0, 7, 72, 0.08));
  backdrop-filter: blur(24.6223px);
  transform: matrix(0.97, 0, -0.25, 1, 0, 0);

  border: 1px solid rgba(255, 255, 255, 0.14) !important;
}

.daily-quest-title-text {
  transform: matrix(0.99, 0, -0.11, 1, 0, 0);
}

.container-daily-quest {
  border: 2px solid rgba(255, 255, 255, 0.34) !important;
}

.daily-quest-button-list {
  /* background: linear-gradient(
    155.14deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  ); */
  background-color: rgb(149, 99, 231);
  filter: drop-shadow(34.2571px 31.0455px 52.4562px rgba(0, 7, 72, 0.08));
  backdrop-filter: blur(24.6223px);
  transform: matrix(0.97, 0, -0.25, 1, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.14) !important;
}
