.wrong-answer {
  background-color: #ff0000 !important;
  color: white !important;
}

.correct-answer {
  background-color: #00d830 !important;
  color: white !important;
}

.wrong-answer-shadow {
  box-shadow: 0 0 20px 7px #ff0000;
}

.correct-answer-shadow {
  box-shadow: 0 0 20px 7px #00d830;
}

.button-yellow {
  background: #dab505;
  border-radius: 10px;
  text-shadow: 0px 2.07958px 4.15917px rgba(0, 0, 0, 0.14),
    0px 2.07958px 2.07958px rgba(0, 0, 0, 0.12),
    0px 2.07958px 4.15917px rgba(0, 0, 0, 0.2);
}

.button-yellow-hover:hover {
  background: #bfa005;
}

.upper-button {
  background: #ffe151;
  border-radius: 48.8702px;
}

.wrong-answer {
  background-color: #ff0000 !important;
}

.correct-answer {
  background-color: #00d830 !important;
}

.wrong-answer-shadow {
  box-shadow: 0 0 20px #ff0000;
}

.correct-answer-shadow {
  box-shadow: 0 0 20px #00d830;
}
