#wheelOfFortuneContainer .roulette-inner-shadow {
    box-shadow:
        inset 0em 0.5em rgba(0, 0, 0, 0.15), inset 0.5em 0 rgba(0, 0, 0, 0.15), inset -0.5em 0 rgba(0, 0, 0, 0.15), inset 0 -0.5em rgba(0, 0, 0, 0.15);
}


#wheelOfFortuneContainer .wheel-of-fortune-text-shadow {
    text-shadow: 1px 0 1px #000,
        0 3px 1px #000,
        -1px 0 1px #000,
        0 -1px 1px #000;
}

#wheelOfFortuneContainer .wheel-of-fortune-box-shadow {
    box-shadow: 1px 0 5px #000, 0 1px 5px #000, -1px 0 5px #000, 0 -1px 5px #000;
}

#wheelOfFortuneContainer .wheel-of-fortune-four-pointed-star:before {
    content: "";
    position: absolute;
    background: rgb(255, 245, 157);
    width: 1em;
    height: 1.15em;
    transform: rotate(-45deg) skewX(22.5deg) skewY(22.5deg);
}

#wheelOfFortuneContainer .wheel-of-fortune-four-pointed-star:after {
    content: "";
    position: absolute;
    background: rgb(255, 245, 157);
    width: 1em;
    height: 1.15em;
    transform: rotate(45deg) skewX(22.5deg) skewY(22.5deg);
}

@media (max-width: 640px) {
    #wheelOfFortuneContainer .wheel-of-fortune-text-shadow {
        text-shadow: 1px 0 1px #000,
            0 2px 1px #000,
            -1px 0 1px #000,
            0 -1px 1px #000;
    }

    #wheelOfFortuneContainer .wheel-of-fortune-four-pointed-star:before {
        width: 0.5em;
        height: 0.65em;
    }

    #wheelOfFortuneContainer .wheel-of-fortune-four-pointed-star:after {
        width: 0.5em;
        height: 0.65em;
    }
}